@import url("https://cdn.jsdelivr.net/npm/vazir-font/dist/font-face.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&family=Rubik:wght@400;500;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}
